<template>
  <v-row class="fill-height">
    <v-col>
      <v-card>
        <v-card-title>
          <v-icon class="mr-2">mdi-handshake</v-icon>
          Benefícios
          <v-spacer></v-spacer>
          <v-text-field
            v-model="paginationOptions.search"
            class="mr-3"
            append-icon="mdi-magnify"
            label="Buscar"
            placeholder="Informe a descrição do beneficio"
            single-line
            hide-details
            @keydown="buscar()"
            @click:append="buscar()"
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>

          <v-alert dense outlined type="warning" v-if="!benefits">
            Benefícios não encontrados
          </v-alert>
        </v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="filteredBenefitsItems()"
          :options.sync="options"
          :server-items-length="benefits.totalItems"
          :search.sync="paginationOptions.search"
          item-key="id"
          class="elevation-1"
          v-show="benefits"
          :footer-props="{
                        'items-per-page-options': [10, 25, 50, 75, 100]
                      }"
        >
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | formatDate2 }}
          </template>
          <template v-slot:item.value="{ item }">
            {{ item.value | money }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#3D1159"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                          name: 'benefit',
                          params: { id: item.id }
                        }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>

      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
// import { mask } from "vue-the-mask";
import {BENEFIT_SEARCH} from "@/store/actions/benefit.type";
import {BENEFIT_RESET_STATE, SET_BENEFIT_PAGINATION} from "@/store/mutations/benefit.type";

export default {
  name: "Benefits",

  // directives: { mask },

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(BENEFIT_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(BENEFIT_RESET_STATE),
      store.dispatch(BENEFIT_SEARCH)
    ]).then(() => {
      next();
    });
  },
  async beforeDestroy() {
      if(!this.$route.path.includes('/benefit')){
          localStorage.removeItem('benefitsOptions')
          localStorage.removeItem('benefitPaginationOptions')
      }
  },

  data: () => ({
    selected: [],
    options: {},
    paginationOptions: {},
    headers: [
      {text: "Data", value: "createdAt"},
      {text: "Empresa", value: "companyName"},
      {text: "Descrição", value: "desc", sortable: false},
      {text: "Status", value: "status"},
      {text: "Valor", value: "value", sortable: false},
      {text: "Visualizar", value: "actions", align: "center", sortable: false}
    ],


  }),
  methods: {
    buscar() {
      const {sortBy, sortDesc, itemsPerPage, page} = this.options
      this.benefitPagination.search = this.paginationOptions.search

      store.commit(SET_BENEFIT_PAGINATION, {
        page: page,
        itemsPerPage: itemsPerPage,
        orderBy: sortBy,
        sortDesc: sortDesc[0]
      })
      store.dispatch(BENEFIT_SEARCH);
    },
    filteredBenefitsItems() { 
        const items = this.benefits.items
        if(this.benefitPagination.search){
            const search = this.benefitPagination.search.toLowerCase()
            this.options.itemsPerPage = this.benefits.totalItems
            this.options.page = 1
            const exactMatches = [];
            const partialMatches = [];

            items.forEach(item => {
                const itemValues = Object.values(item).map(value => String(value).toLowerCase());
                const isExactMatch = itemValues.some(value => value.startsWith(search));
                const isPartialMatch = itemValues.some(value => value.includes(search));
                
                if (isExactMatch) {
                    exactMatches.push(item);
                } else if (isPartialMatch) {
                    partialMatches.push(item);
                }
            });

            return [...exactMatches,...partialMatches];
        }
        else {
            if(this.options.itemsPerPage == this.benefits.totalItems){
                this.options.itemsPerPage = 10
            }
            return items;
        } 
    }
  },
  mounted() {
      if (localStorage.getItem('benefitsOptions')){
          this.options = JSON.parse(localStorage.getItem('benefitsOptions'))
      }
      if (localStorage.getItem('benefitPaginationOptions')){
          this.paginationOptions = JSON.parse(localStorage.getItem('benefitPaginationOptions'))
      }
  },
  
  watch: {
    options: {
      handler(newOptions) {
        this.buscar()
        const parsed = JSON.stringify(newOptions)
        localStorage.setItem('benefitsOptions', parsed)
      }
    },
    paginationOptions: {
        handler(newSearch) {
            this.buscar()
            const parsed = JSON.stringify(newSearch)
            localStorage.setItem('benefitPaginationOptions', parsed)
        },
        deep: true,
    }
  },
  computed: {
    ...mapGetters(["checkUsers", "benefits", "benefitPagination"])
  }
};
</script>
